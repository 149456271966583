import Typography from "typography"
import ElkGlen from "typography-theme-elk-glen"

ElkGlen.overrideThemeStyles = () => {
  return {
    "a.gatsby-resp-image-link": {
      boxShadow: `none`,
    },
    "a": {
      textShadow: 'none',
      backgroundImage: 'none',
    },
    'code': {
      fontSize: '0.7rem',
    },
  }
}

delete ElkGlen.googleFonts

const typography = new Typography(ElkGlen)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
