import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import _ from "lodash";
import { css } from "@emotion/core"
import { GoTag } from 'react-icons/go';


import { rhythm, scale } from "../utils/typography"

class Tag extends React.PureComponent {
    render() {

        const { title } = this.props;

        return (
            <Link
                css={css`
                    color: rgba(0,0,0,.68);
                    margin-left: 0.2rem;
                    margin-right: 0.2rem;
                    background-color: rgba(0,0,0,.05);
                    border-radius: 0.2rem;
                    padding-left: 0.5rem;
                    padding-right: 0.5rem;
                    margin-top: 0.2rem;
                    :focus, :hover, :active {
                        background-color: rgba(0,0,0,.1);
                    }
                `}
                to={`/tags/${_.kebabCase(title)}`}
            >
                {title}
            </Link>
        )
    }
}

Tag.propTypes = {
    title: PropTypes.string,
}

export default Tag
